import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/JVFdmTt88z4"
    bibleGroupSrc="https://www.docdroid.net/MUkELoN/bible-group-homework-5-17-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/13892692"
  >
    <SEO title="Greater Than - Colossians" />
  </Layout>
)

export default SermonPost
